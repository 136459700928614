const projectsInfo = [
  {
    id: 15,
    href: "https://blog.emilianolucero.ar/",
    filename: "blog-tech.png",
    title: "I 🩶 my computer job",
    description:
      "Writings, explorations and stuff. This blog is built with Next.js and TypeScript, utilizing GSAP for animations and Payload as the CMS. I'm collaborating with Ine Labarrère, who created the beautiful illustrations. (It's a work in progress.)",
    tag1: "Next.js",
    tag2: "GSAP",
    tag3: "Typescript",
  },
  {
    id: 14,
    href: "https://bebeto-pizza.vercel.app/nuncasepuedehacermasrapido.html",
    filename: "nuncasepuedehacermasrapido.png",
    title: "Nunca se puede hacer más rápido",
    description:
      "Playing with React Three Fiber, GSAP animations and shaders to showcase a printed zine I released in 2018. (It's a work in progress).",
    tag1: "React Three Fiber",
    tag2: "GSAP",
    tag3: "Shaders",
  },
  {
    id: 13,
    href: "https://cutie-romero.vercel.app",
    filename: "cuti.jpg",
    title: "Cutie Romero",
    description:
      "A Three.js experience that explores the interaction between Twitter fandom and professional football players featuring interactive elements, animations, physics and shaders.",
    tag1: "Three.js",
    tag2: "Javascript",
    tag3: "GSAP",
  },
  {
    id: 12,
    href: "https://www.instagram.com/p/CnPnO8dgXFE/",
    filename: "prato.png",
    title: "Archivo Prato",
    description:
      "This interactive installation was developed in collaboration with my friend Virginia Molinari, utilizing her extensive photography archive. Built with JavaScript and Pixi.js, it incorporates shaders for visual effects and motion detection via a Kinect device. The project was exhibited at the Complejo Astronómico in Rosario, Argentina.",
    tag1: "Javascript",
    tag2: "Pixi.js",
    tag3: "Kinectron",
  },
  {
    id: 11,
    href: "https://github.com/emilianoglucero/emilianolucero-portfolio",
    filename: "portfolio.png",
    title: "Personal Portfolio Website",
    description:
      "Landing page for my portfolio (this site). A great excuse to use TailwindCSS, React and Gatsby.",
    tag1: "React",
    tag2: "TailwindCSS",
    tag3: "Gatsby",
  },
  // {
  //   id: 13,
  //   href: "https://github.com/emilianoglucero/giphy-api-example",
  //   filename: "giphy.jpg",
  //   title: "Giphy searcher",
  //   description:
  //     "A super super super simple gif searcher powered by Giphy. An excuse to do some Unit Testing.",
  //   tag1: "React",
  //   tag2: "UnitTesting",
  //   tag3: "Javascript",
  // },
  {
    id: 10,
    href: "https://github.com/emilianoglucero/twitter-bot",
    filename: "twitter.jpg",
    title: "Twitter Bot",
    description: "A twitter bot made with Javascript, Node.js and Express.",
    tag1: "Node.js",
    tag2: "Javascript",
    tag3: "Express",
  },
  // {
  //   id: 11,
  //   href: "https://www.alphatrainingcenter.com.ar/",
  //   filename: "alpha.jpg",
  //   title: "Alpha Training Center Dojo",
  //   description: "Landing page made for the Alpha Training Center Dojo.",
  //   tag1: "React",
  //   tag2: "Gatsby",
  //   tag3: "Sass",
  // },
  {
    id: 9,
    href: "https://bebeto-pizza.vercel.app/compraventa.html",
    filename: "compraventa.jpg",
    title: "Compraventa",
    description: "Net Art work inspired by a telegram group made with React.",
    tag1: "NetArt",
    tag2: "React",
    tag3: "",
  },
  {
    id: 8,
    href: "https://ateitiscorp.com/",
    filename: "ateitis.jpg",
    title: "Ateitis Corp",
    description:
      "This project was developed in collaboration with my friend Alexis Boni. It features a landing page and ecommerce functionality built with React and Gatsby, using WordPress as the CMS and WooCommerce for the ecommerce platform.",
    tag1: "React",
    tag2: "GraphQL",
    tag3: "Wordpress",
  },
  {
    id: 7,
    href:
      "https://chrome.google.com/webstore/detail/me-encanta-todo-eso/lelgaanomejijmiolbncggicogpalmcb",
    filename: "chrome.jpg",
    title: "Me encanta todo eso",
    description:
      "This Google Chrome plugin transforms the Facebook Desktop UI by modifying the HTML and CSS to change the like count of each post to a large number of 'Love' reactions.",
    tag1: "Javascript",
    tag2: "ChromePlugin",
    tag3: "",
  },
  {
    id: 6,
    href: "https://bebeto-pizza.vercel.app/screenshots.html",
    filename: "screenshots.png",
    title: "Screenshots",
    description:
      "3D experience using Three.js to showcase a printed zine I made in 2019. (It's a work in progress).",
    tag1: "Three.js",
    tag2: "",
    tag3: "",
  },
  {
    id: 5,
    href: "https://bebeto-pizza.vercel.app/dibuja.html",
    filename: "colorea.png",
    title: "Colorea un empresario",
    description:
      "Net Art work. Coloring businessman figures from Argentina. (It's a work in progress).",
    tag1: "p5.js",
    tag2: "Node.js",
    tag3: "Google Cloud Storage",
  },
  {
    id: 4,
    href: "https://bebeto-pizza.vercel.app/maradona.html",
    filename: "diego.jpg",
    title: "Para el pueblo lo mejor",
    description:
      "Net Art work. Inspired in Diego Armando Maradona 💕. (It's a work in progress).",
    tag1: "Javascript",
    tag2: "p5.js",
    tag3: "",
  },
  {
    id: 3,
    href: "https://bebeto-pizza.vercel.app/zarandraca.html",
    filename: "zarandraca.png",
    title: "El viaje de zarandraca",
    description:
      "Net Art and Video Game work dedicated to a friend. Playing with p5.js and phaser.io and inspired by Flappy Bird. (It's a work in progress).",
    tag1: "Javascript",
    tag2: "Node.js",
    tag3: "p5.js",
  },
  // {
  //   id: 3,
  //   href:
  //     "https://play.google.com/store/apps/details?id=com.lse.ar.rosario.art&hl=es&gl=US",
  //   filename: "art.jpg",
  //   title: "Asociación Rosarina de Tenis App",
  //   description:
  //     "Mobile Hybrid App for The Asociación Rosarina de Tenis made with Apache Cordova.",
  //   tag1: "Javascript",
  //   tag2: "ApacheCordova",
  //   tag3: "Framework7",
  // },
  {
    id: 2,
    href: "https://www.instagram.com/ar/532471514229195/",
    filename: "instagram.jpg",
    title: "Bebeto Face Filter",
    description: "Instagram face filter made with Spark AR Studio.",
    tag1: "Spark",
    tag2: "Instagram",
    tag3: "",
  },
  {
    id: 1,
    href: "https://bebeto-pizza.vercel.app/voca.html",
    filename: "boca.jpg",
    title: "Abrí la voki",
    description:
      "Net Art work. Playing with p5.js, access to camera device and facial recognition models. (It's a work in progress).",
    tag1: "NetArt",
    tag2: "Javascript",
    tag3: "p5.js",
  },
]

export default projectsInfo
